import { EngagementOptionEnum, type EngagementOptionEnumType } from '@forgd/supabase'

type DropdownOption<T extends string = string> = {
  value: T
  label: string
}

export const MarketMakersInterestedInPartneringWithOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5+' },
] as const

export const MMRFQTokenSupplyPercentageToAllocateOptions: DropdownOption[] = [
  { value: '0.10-0.25', label: '0.10% - 0.25%' },
  { value: '0.25-0.50', label: '0.25% - 0.50%' },
  { value: '0.50-0.75', label: '0.50% - 0.75%' },
  { value: '0.75-1.00', label: '0.75% - 1.00%' },
  { value: '1.00-1.25', label: '1.00% - 1.25%' },
  { value: '1.25-1.50', label: '1.25% - 1.50%' },
  { value: '1.50-2.00', label: '1.50% - 2.00%' },
  { value: '2.00-3.00', label: '2.00% - 3.00%' },
  { value: '3.00-5.00', label: '3.00% - 5.00%' },
  { value: 'more_than_5.00', label: 'More than 5.00%' },
] as const

export const MMRFQStableCoinBudgetToAllocateOptions: DropdownOption[] = [
  { value: 'unknown', label: "I don't know" },
  { value: '50K-100K', label: '$50K - $100K' },
  { value: '100K-150K', label: '$100K - $150K' },
  { value: '150K-250K', label: '$150K - $250K' },
  { value: '250K-500K', label: '$250K - $500K' },
  { value: '500K-1M', label: '$500K - $1M' },
  { value: '1M-2.5M', label: '$1M - $2.5M' },
  { value: '2.5M-5M', label: '$2.5M - $5M' },
  { value: '5M-7.5M', label: '$5M - $7.5M' },
  { value: '7.5M-10M', label: '$7.5M - $10M' },
] as const

export const MMRFQServiceOptions: DropdownOption[] = [
  { value: 'Exchange Introductions', label: 'Exchange Introductions' },
  { value: 'Treasury Management', label: 'Treasury Management' },
  { value: 'OTC, TWAP Liquidation', label: 'Over-the-counter ("OTC") Trading' },
] as const

export const MMRFQExternalCapitalOptions: DropdownOption[] = [
  { value: 'no_capital', label: 'No capital raised to date' },
  { value: '100K-500K', label: '$100,000 - $500,000' },
  { value: '500K-1000K', label: '$500,000 - $1,000,000' },
  { value: '1000K-2500K', label: '$1,000,000 - $2,500,000' },
  { value: '2500K-5000K', label: '$2,500,000 - $5,000,000' },
  { value: '5000K-10000K', label: '$5,000,000 - $10,000,000' },
  { value: 'more_than_10000K', label: 'More than $10,000,000' },
] as const

export const MMRFQEngagementOptions: (DropdownOption<EngagementOptionEnumType> & {
  description: string
  engagementDescription: string
  loanRequirements: string[]
})[] = [
  {
    value: EngagementOptionEnum.LoanCallOption,
    label: 'Loan + Call Option',
    description:
      'Receives a loan of tokens with the option (but not obligation) to purchase tokens at some future date. Adheres to liquidity KPIs per legal contract.',
    engagementDescription:
      'This type of engagement utilizes a token loan from a project to provide liquidity.\n\nThe loan carries the optional (but not obligation) for the market maker to purchase tokens at some determined price.\n \n',
    loanRequirements: ['Tokens'],
  },
  {
    value: EngagementOptionEnum.RetainerWorkingCapital,
    label: 'Retainer + Working Capital',
    description:
      'Charges monthly fee to operate as a strategic liquidity provider. Trades using your capital (tokens & stablecoins). May receive a profit share.',
    engagementDescription:
      'This type of engagement utilizes both tokens and stablecoins belonging to a project to provide liquidity.\n\nThe market maker is incentivized via service fees and (potentially) profit share arrangements.',
    loanRequirements: ['Tokens', 'Stablecoins'],
  },
] as const

export const MMRFQMotivationsOptions: (DropdownOption & { description: string })[] = [
  {
    value: 'price_performance',
    label: 'Price Performance',
    description: '“I want to achieve the highest possible valuation (FDV).”',
  },
  {
    value: 'user_acquisition',
    label: 'User Acquisition',
    description: '“I want to acquire as many users as possible at a product / protocol level.”',
  },
  {
    value: 'treasury_management',
    label: 'Treasury Management',
    description: '“I want to liquidate tokens to increase its stablecoin reserves and extend the operational runway.”',
  },
  {
    value: 'liquidity_improvement',
    label: 'Liquidity Improvement',
    description: '“I want to ensure robust liquidity to facilitate efficient price discovery for our native token.”',
  },
] as const
